import React, {useRef, useState} from 'react';

import {themeGet} from '@styled-system/theme-get';
import {Box, BoxProps, FormProps} from 'src/components/shared';
import {Typography} from 'src/components/shared/fireworkTypography';
import {useBreakpointValue} from 'src/hooks/useBreakpointValue';
import {useMarketo} from 'src/hooks/useMarketoForm';
import {ErrorSymbol} from 'src/svgs/errorSymbol';
import * as copy from 'src/utils/copy';
import styled from 'styled-components/macro';
import {useChiefInBriefSubscribe} from 'src/hooks/useChiefInBriefSubscribe';

const NewsletterFormSection: React.FC<FormProps> = styled(Box).attrs({
  as: 'form',
  paddingTop: ['40px'],
  paddingX: ['20px'],
  display: 'flex',
  justifyContent: 'center',
})`` as React.FC<FormProps>;

const NewsletterTitle = styled(Typography.H4).attrs({
  whiteSpace: ['normal', 'normal', 'normal', 'normal', 'normal', 'nowrap'],
  minWidth: [null, null, null, null, null, 'min-content'],
  paddingBottom: ['0.825rem', null, null, null, '0rem'],
})``;

const NewsletterInputWrapper = styled(Box).attrs({
  display: 'flex',
  flexDirection: 'column',
  flex: '2',
  borderRadius: '4px',
  border: '1px solid #ebece7',
  overflow: 'hidden',
  gap: '16px',
})``;

const NewsletterEmailInput = styled.input.attrs({
  padding: '16px',
  outline: 'none',
  border: '0px',
})`
  background: ${themeGet('colors.lightGrey')} !important;
  flex: 1;
  border: 0;
  color: ${themeGet('colors.darkGreen')} !important;
`;

const SubscribeButton = styled.button`
  background: ${themeGet('colors.neonGreen')};
  border-radius: 6.25rem;
  color: ${themeGet('colors.darkGreen')};
  padding: 12px;
  font-weight: 800;
  flex: 1;
  text-transform: uppercase;
  letter-spacing: 0.1rem;
`;

const GridSection = styled(Box).attrs({
  display: 'flex',
  color: 'cream',
})`
  button[type='submit'] {
    background-color: ${themeGet('colors.neonGreen')};
  }
`;

const NewsletterFormGrid = styled(Box).attrs({
  display: 'grid',
  backgroundColor: 'darkGreen',
  width: '100%',
  maxWidth: [null, null, null, '614px', '824px', '1160px'],
  paddingX: ['20px', null, null, '24px'],
  paddingTop: ['20px', null, null, null, '36px'],
  borderRadius: '16px',
  gap: '12px',
})``;

const Message = styled.span<{status: string}>`
  font-size: 0.75rem;
  display: flex;
  gap: 8px;
  width: 100%;
  color: ${({status}) => (status === 'success' ? '#a5c09f' : '#f09e8c')};
`;

const validateEmail = (event: React.ChangeEvent<HTMLInputElement>) => {
  // borrowed from: https://github.com/colinhacks/zod/blob/02699107616f672d79d3d71557456ec55597a4fa/src/types.ts#L575
  // eslint-disable-next-line no-useless-escape
  const emailRegex = /^(?!\.)(?!.*\.\.)([A-Z0-9_+-\.]*)[A-Z0-9_+-]@([A-Z0-9][A-Z0-9\-]*\.)+[A-Z]{2,}$/i;
  return emailRegex.test(event.target.value) ? true : false;
};

export type NewsletterFormProps = BoxProps & {id: string; onSubmit?: VoidFunction};

const HiddenMarketoForm = styled.form`
  display: none;
`;

export const NewsletterForm: React.FC<NewsletterFormProps> = ({id, onSubmit}) => {
  const [status, setStatus] = useState<keyof typeof copy.footer.newsletter.status | 'idle'>('idle');
  const [submitting, setSubmitting] = useState<boolean>(false);
  const isDesktop = useBreakpointValue([false, false, false, true]);
  const paddingBottom = !isDesktop ? '36px' : status === 'idle' ? '40px' : '0px';

  const chiefInBriefSubscribe = useChiefInBriefSubscribe();

  /**
   * Almost all of the following business logic is an exact drop-in copy from the old news letter form.
   * the ONLY changes that have been made to the form of course are in reference to styling and new state
   * values
   */
  const emailRef = useRef<HTMLInputElement>(null);

  const handleSubmit: React.FormEventHandler<HTMLFormElement> = async e => {
    e.preventDefault();

    const email = emailRef?.current?.value;

    if (email && status != 'error' && !submitting) {
      setSubmitting(true);

      const result = await chiefInBriefSubscribe(email);

      setSubmitting(false);
      setStatus(result ? 'success' : 'submissionError');
    }

    return false;
  };

  return (
    <NewsletterFormSection id={id} onSubmit={handleSubmit}>
      <NewsletterFormGrid paddingBottom={paddingBottom}>
        <GridSection
          alignItems={'center'}
          color="cream"
          gridColumn={['1', null, null, '1 / 4', '1 / 2']}
          gridRow={['1']}
          width={['100%', null, null, null, '243px', '573px']}
        >
          <NewsletterTitle>{copy.footer.newsletter.title}</NewsletterTitle>
        </GridSection>
        <GridSection gridColumn={['1', null, null, '1 / 3', '2 / 3']} gridRow={['2', null, null, null, '1']}>
          <NewsletterInputWrapper>
            <NewsletterEmailInput
              onChange={e => setStatus(validateEmail(e) ? 'idle' : 'error')}
              ref={emailRef}
              type="email"
              name="email"
              placeholder="Enter your email"
            />
          </NewsletterInputWrapper>
        </GridSection>
        {status !== 'idle' && (
          <GridSection
            paddingBottom={isDesktop ? '12px' : '0px'}
            gridColumn={['1', null, null, '1', '2 / 4']}
            gridRow={['3', null, null, '3', '2']}
          >
            <Message status={status}>
              {['error', 'submissionError'].includes(status) && <ErrorSymbol />} {copy.footer.newsletter.status[status]}
            </Message>
          </GridSection>
        )}
        <GridSection gridColumn={['1', null, null, '3 / 4']} gridRow={['4', null, null, '2', '1']}>
          <SubscribeButton type="submit">{copy.footer.newsletter.subscribeButton}</SubscribeButton>
        </GridSection>
      </NewsletterFormGrid>
    </NewsletterFormSection>
  );
};
