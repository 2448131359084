import {topLevelDomain} from 'src/utils/international';

export const BASE_URL = 'https://www.chief.com';
export const LOGIN = `${process.env.GATSBY_MEMBER_SITE_BASE_URL}/login`;
export const CAREERS = 'https://boards.greenhouse.io/chief';
export const MARKETO_BASE_URL = `//join.chief${topLevelDomain}`;
export const CAREERS_API_URL = 'https://boards-api.greenhouse.io/v1/boards/chief/departments';
export const LEADERSHIP = `${BASE_URL}/articles`;
export const COMMITMENT_TO_DEI = `${BASE_URL}/commitment-to-dei`;
export const UPDATES_BLOG = 'https://updates.chief.com';
export const THE_NEW_ERA = 'https://thenewera.chief.com/';
